const colors = {
  primaryBlue: '#2F6EA0',
  primaryYellow: '#F1D14F',
  darkGreen: '#038712',
  green: '#06C26E',
  red: 'rgba(230, 53, 53, 1)',
  orange: '#F06400',
  pink: '#DF1995',
  blue: '#00AED6',
  purple: '#93328E',
  green100: '#009639',
  green200: '#3aa24f',
  green300: '#58ae64',
  green400: '#72ba79',
  green500: '#8ac58f',
  green600: '#a2d1a5',
  lightGreen: '#00C94C',
  darkBase: '#0f5e9c',
  // base: '#2389da',
  base: '#00AA13',
  complimentBase: '#4DA1E3',
  // base: '#0f5e9c',
  lightBase: '#1ca3ec',
  lighterBase: '#bde6f8',
  dark100: '#252525',
  dark200: '#393939',
  dark300: '#4f4f4f',
  dark400: '#666666',
  dark500: '#7d7d7d',
  dark600: '#969696',
  white: '#FFFFFF',
  blueBackground: '#effbfb',
  background: '#fcfbff',
  background2: 'rgba(117, 117, 117, 0.08)',
  border: '#F0EEF8',
  blackText1: '#212121',
  lightGrayText1: '#757575',
  lightGrayText2: '#BDBDBD',
  // red: '#ed6468',
  // orange: '#ED872E',
  freeBg: 'rgba(162, 209, 165, 0.2)',
  premiumBg: 'rgba(255, 207, 0, 0.2)',
  platinumBg: 'rgba(224, 180, 106, 0.2)',
  platinum: 'rgb(123, 104, 72)',
  gold: '#FFD700',
  baseText: '#404040',
  bgGreen: 'rgba(44, 204, 127, 0.08)',
  borderGreen: 'rgba(44, 204, 127, 0.16)',
  bgOrange: 'rgba(255, 159, 105, 0.08)',
  borderOrange: 'rgba(255, 159, 105, 0.16)',
  bgYellow: 'rgba(241, 209, 79, 0.08)',
  bgRed: 'rgba(254, 233, 233, 1)',
  lightBlue: 'rgba(13, 166, 242, 1)',
};

export default colors;
