import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Job from "./Job"
import Form from "./Form"

export default function Main() {
  const [searchParams, setSearchParams] = useSearchParams()
  const action = searchParams.get('action')
  const id = searchParams.get('id')
  useEffect(() => {
    if (!id || !['job', 'form'].includes(action)) {
      window.location.href = 'https://ordes.co.id'
    }
  }, [id, action])

  return (
    <div className="flex sm:hidden w-full min-h-screen">
      {
        action === 'job' ? <Job /> : <Form />
      }
    </div>
  )
}