import axios from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import colors from "./colors"
import { IconFileSpreadsheet, IconFileTypeDoc, IconFileTypeDocx, IconLoader2, IconPdf, IconPhoto } from "@tabler/icons-react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material"
import { config } from "./config"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import 'dayjs/locale/id';
import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebaseConfig from "./firebaseConfig.json";
import 'react-simple-toasts/dist/theme/light.css';
import toast from "react-simple-toasts"


export default function Form() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [guest, setGuest] = useState('')
  const [form, setForm] = useState(null)
  const [forms, setForms] = useState([])
  const [loading, setLoading] = useState(false)

  const action = searchParams.get('action')
  const id = searchParams.get('id')
  const status = searchParams.get('status')
  const enabled = status === 'draft' || !status

  const fetchJobForm = async () => {
    setLoading(true)
    await axios
      .get(`${config.baseUrl}jobs/guest/job-fields`, {
        headers: {
          'x-code-header': id,
        },
      })
      .then(resp => {
        const f = resp.data;
        let fo = [];
        for (let index = 0; index < f.length; index++) {
          fo.push({...f[index], value: ''});
        }
        setForms(fo);
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      });
  };

  useEffect(() => {
    const localGuest = localStorage.getItem('guest')
    if (!localGuest) {
      setSearchParams({id: id, action: 'job'})
    }
    setGuest(localGuest)
    if (status) {
      setForm(JSON.parse(localStorage.getItem('form')))
      setForms(JSON.parse(localStorage.getItem('form')).form_input_values)
    }else{
      fetchJobForm(localGuest)
    }
  }, [action])

  const changeForm = (index, value) => {
    let newForms = [...forms]
    newForms[index].value = value
    setForms(newForms)
  };

  const pickImage = (e, index) => {
    const file = e.target.files[0];
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      changeForm(index, filename)
    });
  }

  const saveForm = async (action) => {
    if (
      forms.filter((e) => e.value === '' && e.type !== 'location').length >
        0 &&
      action !== 'draft'
    ) {
      toast('Semua form harus diisi', {position: 'top-center', theme: 'light'})
      return;
    }

    const pinnedForm = forms.find((e) => e.is_pinned === 1);
    if (pinnedForm) {
      if (pinnedForm.value.length === 0) {
        toast(`${pinnedForm.label} harus diisi`, {position: 'top-center', theme: 'light'})
        return;
      }
    }

    setLoading(true);
    if (status) {
      await axios
        .put(
          `${config.baseUrl}jobs/guest/edit/logs/` + form.id,
          {
            job_field: forms,
            action: action ?? 'review-job',
            guest_name: guest,
          },
          {
            headers: {
              'x-code-header': id,
            },
          },
        )
        .then(resp => {
          toast('Form berhasil disimpan', {position: 'top-center', theme: 'light'})
          setLoading(false);
          setSearchParams({id: id, action: 'job'})
        })
        .catch(err => {
          toast('Terjadi kesalahan', {position: 'top-center', theme: 'light'})
          setLoading(false);
        });
    } else {
      await axios
        .post(
          `${config.baseUrl}jobs/guest/submit/`,
          {
            job_field: forms,
            action: action ?? 'review-job',
            guest_name: guest,
          },
          {
            headers: {
              'x-code-header': id,
            },
          },
        )
        .then(resp => {
          toast('Form berhasil disimpan', {position: 'top-center', theme: 'light'})
          setLoading(false);
          setSearchParams({id: id, action: 'job'})
        })
        .catch(err => {
          toast('Terjadi kesalahan', {position: 'top-center', theme: 'light'})
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-[#FBFBFB] flex flex-col w-full min-h-screen">
      <header className={`bg-[${colors.primaryYellow}] p-4 flex flex-row items-center justify-between`}>
        <p className="font-bold">ORDES Form</p>
        <p className="font-bold">{guest}</p>
      </header>
      {
        loading && <div className="w-full h-full flex flex-row items-center justify-center">
          <IconLoader2 color={colors.primaryBlue} size={32} className="animate-spin" />
        </div>
      }
      {
        forms.length > 0 && !loading &&
        <div className="p-4 flex flex-col gap-y-3 pb-24">
          {
            forms.map((formData, formIndex) => {
              if (formData.type === 'text') {
                return (
                  <TextField disabled={!enabled} InputProps={{ style: {borderRadius: 12}}} key={formIndex} label={formData.label} value={formData.value} onChange={(e) => { changeForm(formIndex, e.target.value) }} className="rounded-2xl w-full mt-2" variant="outlined" />
                )
              }

              if (formData.type === 'number') {
                return (
                  <TextField type={'number'} disabled={!enabled} InputProps={{ style: {borderRadius: 12}}} key={formIndex} label={formData.label} value={formData.value} onChange={(e) => { changeForm(formIndex, e.target.value) }} className="rounded-2xl w-full mt-2" variant="outlined" />
                )
              }

              if (formData.type === 'options') {
                return (
                  <FormControl sx={{ width: '100%', borderRadius: 12 }} key={formIndex}>
                    <InputLabel>{formData.label}</InputLabel>
                    <Select
                      className="rounded-2xl w-full"
                      disabled={!enabled}
                      value={formData.value}
                      inputProps={{ style: {borderRadius: 12}}}
                      placeholder={`Pilih ${formData.label}`}
                      label={`Pilih ${formData.label}`}
                      sx={{borderRadius: 3}}
                      onChange={(e) => { changeForm(formIndex, e.target.value) }}
                      input={<OutlinedInput label={formData.label} />}
                    >
                      {
                        formData.options.map((e, eIndex) => {
                          return (
                            <MenuItem key={eIndex} value={e}>{e}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                )
              }

              if (formData.type === 'textarea') {
                return (
                  <TextField disabled={!enabled} InputProps={{ style: {borderRadius: 12}}} key={formIndex} multiline rows={4} label={formData.label} value={formData.value} onChange={(e) => { changeForm(formIndex, e.target.value) }} className="rounded-2xl w-full mt-2" variant="outlined" />
                )
              }

              if (formData.type === 'date') {
                return (
                  // <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="id" key={formIndex}>
                  //   <DatePicker onChange={(e) => {console.log(e)}} disabled={!enabled} sx={{borderRadius: 12}} format="DD - MMMM - YYYY" label={formData.label} />
                  // </LocalizationProvider>
                  <TextField placeholder="" disabled={!enabled} type={'date'} InputProps={{ style: {borderRadius: 12}}} key={formIndex} label={formData.label} value={formData.value} onChange={(e) => { changeForm(formIndex, e.target.value) }} className="rounded-2xl w-full mt-2" variant="outlined" />
                )
              }

              if (formData.type === 'image') {
                return (
                  <div className="flex flex-col items-center gap-y-3" key={formIndex}>
                    {formData.value.length > 0 && <img src={formData.value} alt={formData.label} className="object-contain w-3/4" />}
                    <label className={`flex flex-col items-center p-4 border w-full rounded-xl border-[${colors.lightGrayText2}]`}>
                      {enabled && <input type="file" accept=".png,.PNG,.jpg,.jpg" id={`image-${formIndex}`} className="hidden" onChange={(e) => { pickImage(e, formIndex) }} />}
                      <IconPhoto size={36} color={colors.baseText} />
                      <p>{formData.value.length > 0 ? `Ganti ${formData.label}` : formData.label}</p>
                    </label>
                  </div>
                )
              }

              // File (pdf, excel, docx)
              let fileType
              let fileIcon
              switch (formData.type) {
                case 'pdf':
                  fileType = '.pdf'
                  fileIcon = <IconPdf size={36} color={formData.value.length > 0 ? colors.green : colors.baseText} />
                  break;

                case 'excel':
                  fileType = '.xlx,.xlsx'
                  fileIcon = <IconFileSpreadsheet size={36} color={formData.value.length > 0 ? colors.green : colors.baseText} />
                  break;

                case 'docx':
                  fileType = '.docx'
                  fileIcon = <IconFileTypeDocx size={36} color={formData.value.length > 0 ? colors.green : colors.baseText} />
                  break;

                default:
                  fileType = '.pdf'
                  fileIcon = <IconPdf size={36} color={formData.value.length > 0 ? colors.green : colors.baseText} />
                  break;
              }
              return (
                <div className="flex flex-col items-center gap-y-3" key={formIndex}>
                  <label className={`flex flex-col items-center p-4 border w-full rounded-xl border-[${formData.value.length > 0 ? colors.green : colors.lightGrayText2}]`}>
                    {enabled && <input type="file" accept={fileType} id={`${formData.type}-${formIndex}`} className="hidden" onChange={(e) => { pickImage(e, formIndex) }} />}
                    {fileIcon}
                    <p className={`text-[${formData.value.length > 0 ? colors.green : colors.baseText}]`}>{formData.value.length > 0 ? `Ganti ${formData.label}` : formData.label}</p>
                  </label>
                </div>
              )
            })
          }
        </div>
      }
      <div className="p-4 fixed bottom-0 w-full flex flex-row gap-x-2 bg-white">
        <button onClick={() => { !enabled ? setSearchParams({id: id, action: 'job'}) : saveForm('draft') }} type="button" className={`w-full text-center rounded-lg py-2.5 text-[${colors.primaryBlue}] border border-[${colors.primaryBlue}] bg-white`}>{!enabled ? 'Kembali' : 'Simpan Dulu'}</button>
        {enabled && <button onClick={() => { saveForm() }} type="button" className={`w-full text-center rounded-lg py-2.5 text-white bg-[${colors.green}] border border-[${colors.green}]`}>Ajukan</button>}
      </div>
    </div>
  )
}