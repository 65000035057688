import './App.css';
import { Route, Routes } from 'react-router-dom';
import Main from './Main';
import NotFound from './Notfound';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
